import React from 'react';

const Hit = ({ hit, currentUser, toggleFollow, filterQuery }) => {
  return (
    <div key={`conf-hit-${hit._id}`} className="ds-listing ds-listing-artist ds-listing-expandable ds-listing-view-list" data-id={hit._id} title={`View more about ${hit._source.title}`}>
      <div className="ds-cover-image" style={{backgroundImage: `url(https://res-2.cloudinary.com/dostuff-media/image/upload//c_fill,g_faces,b_rgb:090909,q_60,h_450,w_1200/${hit._source.cloudinary_image})`}} title=""></div>
      <span className="ds-slim-avatar" style={{backgroundImage: `url(https://res-2.cloudinary.com/dostuff-media/image/upload//w_50,h_50,c_fill,g_faces/${hit._source.cloudinary_image})`}}></span>
      <div className="ds-listing-action">
        <a href="/artists" onClick={(e) => toggleFollow(e, hit, currentUser)} className={`ds-follow ${currentUser.artists && currentUser.artists.includes(parseInt(hit._id)) ? 'ds-following' : ''}`} data-ds-grouping="artists" data-ds-id={hit._id}>
          <span className="ds-btn-group ds-btn-small ds-follow-default" title="Follow Artist">
            <span className="ds-btn ds-bg-follow">Follow</span>
            <span className="ds-btn ds-bg-follow-acc">
              <span className="ds-icon ds-icon-plus ds-color-white"></span>
            </span>
          </span>
          <span className="ds-btn-group ds-btn-small ds-follow-active" title="Unfollow Artist">
            <span className="ds-btn ds-bg-follow">Unfollow</span>
            <span className="ds-btn ds-bg-follow-acc">
              <span className="ds-icon ds-icon-x ds-color-white"></span>
            </span>
          </span>
        </a>
      </div>
      <div className="ds-title" itemProp="performer" itemScope="" itemType="http://schema.org/Person">
        <a href={`/artists/id/${hit._id}`} itemProp="url" className="ds-slim-title ds-band-title">
          <span itemProp="name">{hit._source.title}</span>
        </a>
        <span className="ds-listing-extras">
          <span className="ds-listing-hometown">
            <a href="/artists" onClick={(e) => filterQuery(e, 'hometown', hit._source.hometown)} className="hometown-facet" title={`Click to view other bands from ${hit._source.hometown}`}> {hit._source.hometown}</a>&nbsp;
            {hit._source.genre_tag &&
              <span className="ds-listing-hometown">
                - <a href="/artists" onClick={(e) => filterQuery(e, 'genre_tag', hit._source.genre_tag)} className="genre-facet" title={`Click to view other bands form ${hit._source.genre}`}> {hit._source.genre}</a>
              </span>
            }
          </span>
        </span>
      </div>
    </div>
  )
}

export default Hit;
