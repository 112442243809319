import React from 'react';
import {
  BrowserRouter as Router,
  Route,
} from 'react-router-dom';
import Show from './Show';

const Clipboard = ({ token, buttonText, clipboardElement, display, offerText, url }) => (
  <Router>
    <Route
      path={[
        '/events/:year/:month/:day/:permalink',
        '/events/id/:id',
        '/events/:year/:month/:day',
        '/cr/:referral_code/events/:event_id',
        '/p/:page_permalink',
        '/*',
      ]}
      render={({ match, props, location }) => (
        <Show match={match} props={props} url={url} display={display} offerText={offerText} loc={location} token={token} buttonText={buttonText} clipboardElement={clipboardElement} />
      )}
    />
  </Router>
);

export default Clipboard;
