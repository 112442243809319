import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectQuestions, selectCurrentUser, selectPartner, selectMinimumAge } from './selectors';
import { Text, Select, DatePicker } from './FormInputs';
import { togglePartnerOptin } from './actions';

const inputMapping = (answerProps) => ({
  date: <DatePicker {...answerProps} />,
  text: <Text {...answerProps} />,
  phone_number: <Text {...answerProps} />,
  select: <Select {...answerProps} />,
}[answerProps.question.question_type]);

const Questions = ({ reset, control, errors, watch, trigger }) => {
  const ignore = ['First name', 'Last name', 'Zipcode', 'Email', 'Gender', 'Mobile number', 'Birthdate'];
  const questions = useSelector(selectQuestions);
  const currentUser = useSelector(selectCurrentUser);
  const partner = useSelector(selectPartner);
  const minimumAge = useSelector(selectMinimumAge);
  const dispatch = useDispatch();

  const answerProps = {
    reset,
    control,
    errors,
    watch,
    currentUser,
    trigger,
  };

  const questionsLayout = () => {
    const custom_questions = [];
    questions.forEach((question, index) => {
      if (!ignore.includes(question.body)) {
        custom_questions.push(inputMapping({
          ...answerProps,
          name: `answers[${index}].answer`,
          required: question.required,
          question,
          index,
          isQuestion: true,
          hint: question.hint,
          id: question.id,
          body: question.body,
          key: `answer-input-for-${question.id}`,
        }));
      }
    });
    return [custom_questions];
  };

  const layedOutQuestions = questionsLayout();

  const rowKeys = ['main-questions-1', 'main-questions-2', 'main-questions-3', 'custom-questions'];

  const first_name = questions.filter(p => p.body.toLowerCase() === 'first name')[0];
  const email = questions.filter(p => p.body.toLowerCase() === 'email')[0];
  const last_name = questions.filter(p => p.body.toLowerCase() === 'last name')[0];
  const zipcode = questions.filter(p => p.body.toLowerCase() === 'zipcode')[0];
  const birthdate = questions.filter(p => p.body.toLowerCase() === 'birthdate')[0];
  const mobile_number = questions.filter(p => p.body.toLowerCase() === 'mobile number')[0];
  const gender = questions.filter(p => p.body.toLowerCase() === 'gender')[0];

  return (
    <form className="clipboard-form">
      <div className="row">
        {first_name.enabled &&
          <div className="col-6 col-md-3 clipboard-first-name">
            <Text
              name="first_name"
              required={first_name.required}
              isQuestion={false}
              body="First name"
              hint={first_name.hint}
              key="question-input-for-first-name"
              {...answerProps}
            />
          </div>
        }
        {last_name.enabled &&
          <div className="col-6 col-md-3 clipboard-last-name">
            <Text
              name="last_name"
              required={last_name.required}
              isQuestion={false}
              body="Last name"
              hint={last_name.hint}
              key="question-input-for-last-name"
              {...answerProps}
            />
          </div>
        }
        {email.enabled &&
          <div className="col-sm-12 col-md-6 clipboard-email">
            <Text
              name="email"
              required={email.required}
              isQuestion={false}
              isEmail
              body="Email"
              hint={email.hint}
              key="question-input-for-email"
              type="email"
              {...answerProps}
            />
          </div>
        }
      </div>
      <div className="row">
        {birthdate.enabled &&
          <div className="col-6 col-md-3 clipboard-birthdate">
            <DatePicker
              name="birthdate"
              required={birthdate.required}
              isQuestion={false}
              body="Birthdate"
              hint={birthdate.hint}
              key="question-input-for-birthdate"
              minimumAge={minimumAge}
              {...answerProps}
            />
          </div>
        }
        {mobile_number.enabled &&
            <div className="col-6 col-md-3 clipboard-mobile-number">
              <Text
                name="mobile_number"
                required={mobile_number.required}
                isQuestion={false}
                body="Mobile number"
                mask="999-999-9999"
                hint={mobile_number.hint}
                key="question-input-for-mobile-number"
                type="tel"
                {...answerProps}
              />
            </div>
        }
        {zipcode.enabled &&
          <div className="col-6 col-md-3 clipboard-zipcode">
            <Text
              name="zipcode"
              required={zipcode.required}
              isQuestion={false}
              body="Zipcode"
              hint={zipcode.hint}
              key="question-input-for-zipcode"
              {...answerProps}
            />
          </div>
        }
        {gender.enabled &&
          <div className="col-6 col-md-3 clipboard-gender">
            <Select
              name="gender"
              required={gender.required}
              isQuestion={false}
              body="Gender"
              hint={gender.hint}
              key="question-input-for-gender"
              options={['male', 'female', 'other']}
              {...answerProps}
            />
          </div>
        }
      </div>
      {layedOutQuestions.map((cols, index) => (
        <div className="row" key={`row-${rowKeys[index]}`}>
          {cols.map(col => <div key={`col-for-${col.key}`} className={`col-sm-12 col-md-6 clipboard-custom-question clipboard-custom-question-${col.key}`}>{col}</div>)}
        </div>
      ))}
      {partner.optin && 
        <div className="form-check clipboard-opt-in">
          <input onClick={() => dispatch(togglePartnerOptin())} className="form-check-input" type="checkbox" defaultChecked id="flexCheckDefault" />
          <label className="form-check-label" htmlFor="flexCheckDefault">
            {partner.message}
          </label>
        </div>
      }
    </form>
  );
};

export default Questions;
