import React from 'react';
import { useSelector } from 'react-redux';
import { selectTemplates } from './selectors';

const Bottom = () => {
  const template = useSelector(selectTemplates).filter(temp => temp.template_for.match(/bottom/));

  return (
    <>
      <div className="clipboard-bottom-text" dangerouslySetInnerHTML={{ __html: template[0].body }} />
    </>
  );
};

export default Bottom;
