import React from 'react'
import moment from 'moment';

const Event = ({ item }) => (
  <React.Fragment>
    <div className="ds-result-date-break">
      {moment(item._source.begin_date).format('dddd MMMM Do')}
    </div>
    <div className="ds-search-result" data-ds-ga-label="label">
      <a href={`/event/${item._id}`}>
        <span className="ds-byline">{item._source.presented_by}</span>
        <span className="ds-result-title">{item._source.title}</span>
      </a>
    </div>
  </React.Fragment>
)

export default Event;
