import {
  SET_CLIPBOARD,
  SET_CURRENT_USER,
  ENTERED,
  TOGGLE_PARTNER_OPTIN,
  TOGGLE_LOADING,
  CLEAR_CLIPBOARD,
} from './constants';

const intialState = {
  clipboard: { questions: [], setting: {}, giveaway_templates: [] },
  currentUser: window.clipboard ? { ...window.clipboard.currentUser } : {},
  currentPage: window.clipboard ? { ...window.clipboard.currentPage } : {},
  entry: null,
  referral_code: null,
  partnerOptin: true,
  loading: true,
};

function clipboardReducer(state = intialState, action) {
  switch (action.type) {
    case SET_CLIPBOARD: {
      const { clipboard } = action;
      return {
        ...state,
        clipboard,
      };
    }
    case SET_CURRENT_USER: {
      const { currentUser } = action;
      return {
        ...state,
        currentUser,
      };
    }
    case TOGGLE_PARTNER_OPTIN: {
      return {
        ...state,
        partnerOptin: !state.partnerOptin,
      };
    }
    case TOGGLE_LOADING: {
      return {
        ...state,
        loading: !state.loading,
      };
    }
    case ENTERED: {
      const { entry, referral_code, existing_entry } = action;
      return {
        ...state,
        clipboard: {
          ...state.clipboard,
          existing_entry,
        },
        entry,
        referral_code,
      };
    }
    case CLEAR_CLIPBOARD: {
      return intialState;
    }
    default:
      return state;
  }
}

export default clipboardReducer;
