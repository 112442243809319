import React from 'react';
import { useSelector } from 'react-redux';
import { selectTemplates } from './selectors';

const ExistingEntry = ({ showEntryConfirmation }) => {
  const templates = useSelector(selectTemplates);
  const template = templates.filter(temp => temp.template_for.match(/existing/))[0].body;
  return (
    <div>
      {showEntryConfirmation && <p>You are confirmed!</p>}
      <div className="clipboard-existing-entry" dangerouslySetInnerHTML={{ __html: template.body }} />
    </div>
  );
};

export default ExistingEntry;
