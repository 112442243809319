import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactModal from 'react-modal';
import { useForm } from 'react-hook-form';
import { getClipboard, submitEntry } from './actions';
import { 
  selectClipboard,
  selectEntry,
  selectQuestions,
  selectCurrentUser,
  selectPartnerOptin,
  selectLoading,
} from './selectors';
import Inline from './Inline';
import Modal from './Modal';

ReactModal.setAppElement('body');

const setDefaultUserDetail = (currentUser, field) => {
  if (field === 'date') {
    const date = new Date();
    date.setDate(date.getDate() - 18);
    date.setFullYear(date.getFullYear() - 18);
    return date;
  }
  if (field === 'select') {
    return 'default';
  }
  if (currentUser.logged_in) {
    return currentUser[field];
  }
  return '';
};

const Show = ({ token, buttonText, loc, display, offerText, url }) => {
  const ignore = ['First name', 'Last name', 'Zipcode', 'Email', 'Gender', 'Mobile number', 'Birthdate'];
  const dispatch = useDispatch();
  const loading = useSelector(selectLoading);

  const clipboard = useSelector(selectClipboard);
  const entry = useSelector(selectEntry);
  const questions = useSelector(selectQuestions);
  const currentUser = useSelector(selectCurrentUser);
  const partnerOptin = useSelector(selectPartnerOptin);

  const [modalOpen, setModalOpen] = useState(false);
  const [showLegal, setShowLegal] = useState(false);
  const [showEntryConfirmation, setShowEntryConfirmation] = useState(false);
  const [referralCode, setReferralCode] = useState(null);
  const [recaptchaVerified, setRecaptchaVerified] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState('');

  const toggleModal = () => setModalOpen(!modalOpen);
  
  useEffect(() => {
    if ((loc.hash === '#giveaway' || loc.hash === '#rsvp' || loc.hash.match('#ref|#entry-confirmation')) && display === 'modal') {
      setModalOpen(!modalOpen);
    }
    if (loc.hash.match('#entry-confirmation')) {
      const userId = loc.hash.split('-')[2];
      setShowEntryConfirmation(!showEntryConfirmation);
      dispatch(getClipboard(token, userId));
    } else if (loc.hash.match('#ref')) {
      const referralC = loc.hash.split('-')[1];
      setReferralCode(referralC);
      dispatch(getClipboard(token, ''));
    } else if (currentUser.logged_in) {
      dispatch(getClipboard(token, currentUser.id));
    } else {
      dispatch(getClipboard(token, ''));
    }
  }, []);

  const { handleSubmit, reset, control, formState, formState: { errors }, watch, trigger } = useForm({
    defaultValues: {
      first_name: setDefaultUserDetail(currentUser, 'first_name'),
      last_name: setDefaultUserDetail(currentUser, 'last_name'),
      email: setDefaultUserDetail(currentUser, 'email'),
      mobile_number: '',
      birthdate: setDefaultUserDetail(currentUser, 'date'),
      gender: '',
      zipcode: '',
      answers: questions.filter(q => !ignore.includes(q.body)).map(question => ({
        answer: '',
        question_id: question.id,
      })),
    },
  });

  const onRecaptchChange = (t) => {
    console.log(t);
    setRecaptchaToken(t);
    setRecaptchaVerified(true);
  };

  const onRecaptchError = () => setRecaptchaVerified(false);

  const onSubmit = (params) => {
    const finalParams = { ...params };
    const answers = params.answers.filter(n => n);
    finalParams.token = token;
    finalParams.answers = answers;
    if (referralCode) {
      finalParams.referral_code = referralCode;
    } else {
      finalParams.referral_code = 'none';
    }

    finalParams.metro_id = currentUser.metro_id;
    finalParams.partner_optin = partnerOptin;
    finalParams.rt = recaptchaToken;
    if (url === true) {
      finalParams.origin_url = window.location.href;
    } else {
      finalParams.origin_url = `${window.location.origin}${url}`;
    }
    dispatch(submitEntry(finalParams));
  };

  const toggleShowLegal = () => {
    if (clipboard.setting.legal_url !== '' && clipboard.setting.legal_url !== null) {
      window.open(clipboard.setting.legal_url, '_blank');
    } else {
      setRecaptchaVerified(false);
      setShowLegal(!showLegal);
    }
  };

  const formProps = {
    reset,
    control,
    errors,
    watch,
    currentUser,
    trigger,
    formState,
  };

  const displayProps = {
    modalOpen,
    loading,
    url,
    clipboard,
    formProps,
    onRecaptchChange,
    onRecaptchError,
    token,
    entry,
    toggleModal,
    showLegal,
    toggleShowLegal,
    showEntryConfirmation,
    handleSubmit: handleSubmit(onSubmit),
    buttonText,
    offerText,
    recaptchaVerified,
  };

  const layout = () => ({
    inline: <Inline {...displayProps} />,
    modal: <Modal {...displayProps} />,
  }[display]);

  return (
    <>
      {layout()}
    </>
  );
};

export default Show;
