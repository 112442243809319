import React, { useRef, useState } from 'react';
import Service from '../Utils/Service';
import searchQuery from './searchQuery';

const Event = () => {
  const venueInput = useRef(null);
  const bandInput = useRef(null);
  const [venueCSS, setVenueCSS] = useState({ display: "none" });
  const [bandCSS, setBandCSS] = useState({ display: "none" });
  const [bands, setBands] = useState(window.SEARCH.event_bands);
  const [venue, setVenue] = useState({id: window.SEARCH.event_venue_id, title: window.SEARCH.event_venue_title});
  const [results, setResults] = useState({ bands: [], venues: [] });

  const getPosition = (elem, visible = true) => {
    let input;
    if (elem === 'venues') {
      input = venueInput;
    }
    if (elem === 'bands') {
      input = bandInput;
    }
    if (visible) {
      const positions = input.current.getBoundingClientRect();
      console.log(positions);
      return {
        top: (positions.top + positions.height),
        left: positions.left,
        width: (positions.width),
        display: 'block',
      }
    }
    return {
      display: 'none'
    }
  }

  const searchRequest = (term, model) => {
    const query = searchQuery(term, model);
    const request = Service.post(
      'https://search.dostff.co',
      query,
      (status, data) => {
        if (status === 200) {
          return data;
        }
      }
    );
    return request;
  }

  const queryEs = (term, model) => {
    const res = searchRequest(term, model);
    let other = 'bands';
    if (model === 'bands') {
      other = 'venues';
    }
    res.then(data => {
      setResults({[model]: data.responses[0].hits.hits, [other]: []});
    })
  }

  const search = (term, elem, visible) => {
    if (elem === 'venues') {
      setVenueCSS(getPosition(elem, visible));
    } else {
      setBandCSS(getPosition(elem, visible));
    }
    queryEs(term, elem);
  }

  const hideDropdown = (elem, visible) => {
    setTimeout(() => {
      console.log(1)
      let other = 'bands';
      if (elem === 'bands') {
        other = 'venues';
      }
      if (elem === 'venues') {
        setVenueCSS(getPosition(elem, visible));
      } else {
        setBandCSS(getPosition(elem, visible));
      }
      setResults({[elem]: [], [other]: []});
    }, 200)
  }

  const removeBand = (e, id) => {
    e.preventDefault();
    const newBands = [...bands];
    const idx = bands.findIndex(o => o.id === id);
    newBands.splice(idx, 1)
    setBands(newBands);
  }

  const addBand = (e, hit) => {
    e.preventDefault();
    const newBands = [...bands];
    const newerBands = newBands.concat({ title:  hit._source.title, id: hit._id});
    setBands(newerBands);
    bandInput.current.value = '';
  }

  const assignVenue = (e, hit) => {
    e.preventDefault();
    venueInput.current.value = hit._source.title;
    setVenue({id: hit._id, title: hit._source.title});
  }

  return (
    <div className="ds-form-row">
      <div className="ds-form-col">
        <div className="ds-form-section">
          <label className="ds-field-required" htmlFor="venue_title_es">Venue (<a href="/venues/new" target="_blank">or add a new venue</a>)</label>
          <div className="ds-field">
            <span role="status" aria-live="polite" className="ui-helper-hidden-accessible" />
            <input ref={venueInput} onBlur={() => hideDropdown('venues', false)} onChange={(e) => search(e.target.value, 'venues', true)} className="ds-stretch ui-autocomplete-input" data-ds-hidden-field="event_venue_id" id="venue_title_es" name="venue_title_es" type="text" defaultValue={venue.title} autoComplete="off" />
            <input id="event_venue_id" name="event[venue_id]" type="hidden" value={venue.id} />
          </div>
        </div>
        <ul className="ui-autocomplete ui-front ui-menu ui-widget ui-widget-content ui-corner-all" id="ui-id-1" tabIndex="0" style={venueCSS}>
          {results.venues.map(venue => (
            <li className="ui-menu-item" role="presentation">
              <a href="/vd" onClick={(e) => assignVenue(e, venue)} id="ui-id-3" className="ui-corner-all" tabIndex="-1">
                {venue._source.title}
              </a>
            </li>
          ))}
        </ul>
      </div>
      <div className="ds-form-col">
        <div className="ds-form-section">
          <label htmlFor="bands_venue_id">Bands (<a href="/artists/new" target="_blank">or add a new artist</a>)</label>
          <div className="ds-field">
            <span role="status" aria-live="polite" className="ui-helper-hidden-accessible" />
            <input ref={bandInput} onBlur={() => hideDropdown('bands', false)} onChange={(e) => search(e.target.value, 'bands', true)} className="ds-stretch ui-autocomplete-input" data-ds-hidden-field="bands_venue_id" id="bands_venue_id" name="bands_venue_id" type="text" autoComplete="off" />
          </div>
        </div>
        <ul className="ui-autocomplete ui-front ui-menu ui-widget ui-widget-content ui-corner-all" id="ui-id-1" tabIndex="0" style={bandCSS}>
          {results.bands.map(band => (
            <li className="ui-menu-item" role="presentation">
              <a href="/vd" onClick={(e) => addBand(e, band)} id="ui-id-3" className="ui-corner-all" tabIndex="-1">
                {band._source.title}
              </a>
            </li>
          ))}
        </ul>
      </div>
      <div className="ds-form-col">
        <ol id="ds-event-bands" className="ds-event-bands ui-sortable">
            {bands.map(band => (
              <li className="ds-added">
                {band.title}
                <a className="remove ds-icon ds-icon-x" href="/remove" title='Remove band' onClick={(e) => removeBand(e, band.id)}></a>
                <input type="hidden" value={band.id} name="event[band_ids][]" />
              </li>
            ))}
        </ol>
      </div>
    </div>
  )
}

export default Event;