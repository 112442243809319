import React from 'react';
import Event from './Event';
import Venue from './Venue';
import Band from './Band';
import User from './User';
import Page from './Page';

const getDisplay = (model, item) => (
  {
    events: <Event key={`event-result-${item._id}`} item={item} />,
    venues: <Venue key={`venue-result-${item._id}`} item={item} />,
    artists: <Band key={`band-result-${item._id}`} item={item} />,
    lists: <User key={`user-result-${item._id}`} item={item} />,
    articles: <Page key={`page-result-${item._id}`} item={item} />,
  }[model.toLowerCase()]
)

const ResultGroup = ({ group }) => {
  return (
    <div className={`ds-results-group`}>
      <div key={`sg-${group.model}`} className="ds-results-heading">
        {group.hits.length} of {group.counts.total} {group.model}
      </div>
      {group.hits.map(hit => (
        getDisplay(group.model, hit)
      ))}
    </div>
  );
}

export default ResultGroup;
