import React, { useState, useEffect } from 'react';
import Service from '../Utils/Service';
import searchQuery from '../Utils/searchQuery';
import ResultGroup from './ResultGroup';

const FullSearch = () => {

  const [results, setResults] = useState({ events: [], users: [], bands: [], pages: [], venues: [] });
  const [counts, setCounts] = useState({total: 0, events: 0, users: 0, bands: 0, pages: 0, venues: 0 });
  const [term, setTerm] = useState('');
  const [activeTab, setActivetab] = useState('all');
  const [defaultQuery, setDefaultQuery] = useState('');

  const searchRequest = (term) => {
    const query = searchQuery(term, 25);
    const request = Service.post(
      'https://search.dostff.co',
      query,
      (status, data) => {
        if (status === 200) {
          return data;
        }
      }
    );
    return request;
  }

  const queryEs = (_term) => {
    setTerm(_term);
    if (_term.length > 1) {
      const res = searchRequest(_term);
      window.history.pushState(null, '', `/search?query=${_term}`);
      res.then(data => {
        const total = data.responses.reduce((total, resultSet) => total + resultSet.hits.total.value, 0);
        setCounts({total, events: data.responses[0].hits.total.value, users: data.responses[1].hits.total.value, bands: data.responses[2].hits.total.value, venues: data.responses[3].hits.total.value, pages: data.responses[4].hits.total.value });
        setResults({events: data.responses[0].hits.hits, users: data.responses[1].hits.hits, bands: data.responses[2].hits.hits, venues: data.responses[3].hits.hits, pages: data.responses[4].hits.hits });
      })
    } else if (_term.length === 0 || _term === "") {
      window.history.pushState(null, '', `/search`);
      setTerm('');
      setResults({ events: [], users: [], bands: [], pages: [], venues: [] })
      setCounts({total: 0, events: 0, users: 0, bands: 0, pages: 0, venues: 0 })
    }
  }

  const getUrlVars = () => {
    const vars = {};
    window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m,key,value) {
        vars[key] = value;
    });
    return vars;
  }
  
  const switchTab = (e, tabName) => {
    e.preventDefault();
    setActivetab(tabName);
  }

  useEffect(() => {
    const ps = getUrlVars();
    if (ps.query !== undefined) {
      setDefaultQuery(decodeURI(ps.query));
      queryEs(decodeURI(ps.query));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <div className="ds-listing-nav section">
        <div className="ds-cover-image"></div>
        <div className="ds-container">
          <div className="ds-main">
            <input onChange={(e) => queryEs(e.target.value) } autoComplete="off" type="text" defaultValue={defaultQuery} placeholder='Search term...' className="stretch" id="search_page_query" name="query" />
            <div className="ds-results-groups-nav">
              <div className="ds-btn-group">
              <a href={`/search?query=${term}`} onClick={(e) => switchTab(e, 'all')} className={`ds-btn ds-btn-medium ds-search-section ds-search-all ${activeTab === 'all' ? 'ds-active' : ''}`} data-search-section="all" title="<%= t 'search.categories.tooltips.events' %>">
                  ALL
                  {term !== "" && <span className="ds-search-count"> ({counts.total})</span>}
                </a>
                <a href={`/search?query=${term}`} onClick={(e) => switchTab(e, 'events')} className={`ds-btn ds-btn-medium ds-search-section ds-search-all ${activeTab === 'events' ? 'ds-active' : ''}`} data-search-section="events" title="<%= t 'search.categories.tooltips.events' %>">
                  EVENTS
                  {term !== "" && <span className="ds-search-count"> ({counts.events})</span>}
                </a>
                <a href={`/search?query=${term}`} onClick={(e) => switchTab(e, 'venues')} className={`ds-btn ds-btn-medium ds-search-section ds-search-all ${activeTab === 'venues' ? 'ds-active' : ''}`} data-search-section="venues" title="<%= t 'search.categories.tooltips.venues' %>">
                  VENUES
                  {term !== "" && <span className="ds-search-count"> ({counts.venues})</span>}
                </a>
                <a href={`/search?query=${term}`} onClick={(e) => switchTab(e, 'artists')} className={`ds-btn ds-btn-medium ds-search-section ds-search-all ${activeTab === 'artists' ? 'ds-active' : ''}`} data-search-section="bands" title="<%= t 'search.categories.tooltips.artists' %>">
                  ARTISTS
                  {term !== "" && <span className="ds-search-count"> ({counts.bands})</span>}
                </a>
                <a href={`/search?query=${term}`} onClick={(e) => switchTab(e, 'lists')} className={`ds-btn ds-btn-medium ds-search-section ds-search-all ${activeTab === 'lists' ? 'ds-active' : ''}`} data-search-section="users" title="<%= t 'search.categories.tooltips.lists' %>">
                  LISTS
                  {term !== "" && <span className="ds-search-count"> ({counts.users})</span>}
                </a>
                <a href={`/search?query=${term}`} onClick={(e) => switchTab(e, 'articles')} className={`ds-btn ds-btn-medium ds-search-section ds-search-all ${activeTab === 'articles' ? 'ds-active' : ''}`} data-search-section="pages" title="<%= t 'search.categories.tooltips.pages' %>">
                  ARTICLES
                  {term !== "" && <span className="ds-search-count"> ({counts.pages})</span>}
                </a>
              </div>
            </div>
          </div>
          <div className="ds-sidebar">
            
          </div>
        </div>
      </div>
      <div id="ds-paginated-list" className="section ds-listings-list ds-search-listings">
        <div className="ds-listings-main section">
          <div className="ds-container">
            <div className="ds-main">
              <div className="ds-search-results">
                <div className="ds-search-groups">
                  {counts.total === 0 && <h3>No Results</h3>}
                  {(['all', 'events'].includes(activeTab) && results.events.length > 0) && <ResultGroup group={{model: 'Events', hits: (activeTab === "events" ? results.events : results.events.slice(0, 3)), counts: {total: counts.events}}} />}
                  {(['all', 'venues'].includes(activeTab) && results.venues.length > 0) && <ResultGroup group={{model: 'Venues', hits: (activeTab === "venues" ? results.venues : results.venues.slice(0, 3)), counts: {total: counts.venues}}} />}
                  {(['all', 'artists'].includes(activeTab) && results.bands.length > 0) && <ResultGroup group={{model: 'Artists', hits: (activeTab === "artists" ? results.bands : results.bands.slice(0, 3)), counts: {total: counts.bands}}} />}
                  {(['all', 'lists'].includes(activeTab) && results.users.length > 0) && <ResultGroup group={{model: 'Lists', hits: (activeTab === "lists" ? results.users : results.users.slice(0, 3)), counts: {total: counts.users}}} />}
                  {(['all', 'articles'].includes(activeTab) && results.pages.length > 0) && <ResultGroup group={{model: 'Articles', hits: (activeTab === "articles" ? results.pages : results.pages.slice(0, 3)), counts: {total: counts.pages}}} />}
                </div>
              </div>
            </div>
            <div className="ds-sidebar  ds-listing-sidebar">
              {window.SEARCH.sidebar_top !== undefined && <span dangerouslySetInnerHTML={{__html: window.SEARCH.sidebar_top}} />}
              <div className="ds-ad"></div>
              <div className="ds-display-ad ds-module" id="ds-sidebar-ad"></div>
              <div className="ds-featured-events" style={{display: "none"}}>
                <h3>Featured Events</h3>
                <div className="ds-module-featured-events"></div>
              </div>
              {window.SEARCH.sidebar_bottom !== undefined && <span dangerouslySetInnerHTML={{__html: window.SEARCH.sidebar_bottom}} />}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default FullSearch;
