const metroOrConference = () => {
  if (window.DSGlobal.property.lens === undefined) {
    return {
      metro_id: [window.DSGlobal.property.property_id],
    };
  } else if (
    window.DSGlobal.property.lens !== undefined &&
    window.DSGlobal.property.lens.conference === false
  ) {
    return {
      metro_id: [window.DSGlobal.property.property_id],
    };
  }
  return {
    conference_id: [window.DSGlobal.property.lens.id],
  };
};

const bandsPropertyScoping = (term) => {
  const matches = [
    {
      match: {
        title: term,
      },
    },
  ];
  if (window.DSGlobal.property.lens !== undefined) {
    matches.push({
      terms: {
        conference_gig_ids: [window.DSGlobal.property.lens.id],
      },
    });
  }
  return matches;
};

const searchQuery = (term, size = 3) => {
  const today = Math.ceil(new Date().getTime() / 1000);
  const parts = [
    {
      index: 'events_search_production',
      query: {
        _source: ['title', 'begin_date', 'presented_by'],
        size,
        highlight: {
          pre_tags: ["<span class='ds-search-result-matched'>"],
          post_tags: ['</span>'],
          fields: {
            title: {},
          },
        },
        query: {
          bool: {
            must: [
              {
                multi_match: {
                  query: term,
                  fields: ['title', 'search_keywords', 'presented_by'],
                },
              },
              {
                terms: metroOrConference(),
              },
              {
                bool: {
                  should: [
                    {
                      range: {
                        begin_timestamp_for_search: {
                          gte: today,
                        },
                      },
                    },
                    {
                      range: {
                        end_timestamp_for_search: {
                          gte: today,
                        },
                      },
                    },
                  ],
                },
              },
            ],
          },
        },
      },
    },
    {
      index: 'users_search_production',
      query: {
        _source: ['name', 'display_name', 'username', 'user_type'],
        size,
        highlight: {
          pre_tags: ["<span class='ds-search-result-matched'>"],
          post_tags: ['</span>'],
          fields: {
            display_name: {},
          },
        },
        query: {
          bool: {
            must: [
              {
                multi_match: {
                  query: term,
                  fields: ['name', 'display_name'],
                },
              },
              {
                terms: {
                  metro_preferences_for: [window.DSGlobal.property.property_id],
                },
              },
            ],
          },
        },
      },
    },
    {
      index: 'bands_search_production',
      query: {
        _source: ['title', 'hometown'],
        size,
        highlight: {
          pre_tags: ["<span class='ds-search-result-matched'>"],
          post_tags: ['</span>'],
          fields: {
            title: {},
          },
        },
        query: {
          bool: {
            must: bandsPropertyScoping(term),
          },
        },
      },
    },
    {
      index: 'venues_search_production',
      query: {
        _source: ['title', 'address', 'neighborhood_title'],
        size,
        highlight: {
          pre_tags: ["<span class='ds-search-result-matched'>"],
          post_tags: ['</span>'],
          fields: {
            title: {},
          },
        },
        query: {
          bool: {
            should: [
              {
                match: {
                  title: {
                    analyzer: 'autocomplete',
                    query: term,
                  },
                },
              },
            ],
            filter: [
              {
                terms: {
                  metro_id: [window.DSGlobal.property.property_id],
                },
              },
            ],
          },
        },
      },
    },
    {
      index: 'pages_search_production',
      query: {
        _source: ['title', 'uri'],
        size,
        highlight: {
          pre_tags: ["<span class='ds-search-result-matched'>"],
          post_tags: ['</span>'],
          fields: {
            title: {},
          },
        },
        query: {
          bool: {
            must: [
              {
                match: {
                  title: term,
                },
              },
              {
                terms: {
                  metro_id: [window.DSGlobal.property.property_id],
                },
              },
            ],
          },
        },
      },
    },
  ];
  const finalQuery = [];
  parts.forEach((part) =>
    finalQuery.push(
      JSON.stringify({ index: part.index }),
      JSON.stringify(part.query)
    )
  );
  const query = `${finalQuery.join('\n')}\n`;
  return query;
};

export default searchQuery;
