const bandsPropertyScoping = (term) => {
  const matches = [
    {
      match:{
        title: term
      }
    }
  ]
  if (window.DSGlobal.property.lens !== undefined) {
    matches.push({
      terms: {
        conference_gig_ids: [
          window.DSGlobal.property.lens.id
        ]
      }
    })
  }
  return matches;
}

const searchQuery = (term, model) => {
  const parts = {
    bands: {
      index: "bands_search_production",
      query: {
        _source: ['title'],
        size: 20,
        query: {
          bool: {
            must: bandsPropertyScoping(term)
          }
        }
      }
    },
    venues: {
      index: "venues_search_production",
      query: {
        _source: ['title'],
        size: 20,
        highlight: {
          pre_tags: ["<span class='ds-search-result-matched'>"],
          post_tags: ["</span>"],
          fields: {
            title: {}
          }
        },
        query: {
          bool: {
            must: [
              {
                match:{
                  title: term
                }
              },
              {
                terms: {
                  metro_id: [
                    window.DSGlobal.property.property_id
                  ]
                }
              }
            ]
          }
        }
      }
    }
  }
  const finalQuery = [];
  [parts[model]].forEach(part => (
    finalQuery.push(JSON.stringify({index: part.index}), JSON.stringify(part.query))
  ))
  const query = finalQuery.join("\n") + "\n";
  return query;
};

export default searchQuery;