const bandsPropertyScoping = (term, filter) => {
  const matches = [
  ];
  if (term === "") {
    matches.push(    {
      match_all: {}
    })
  } else {
    matches.push(    {
      match:{
        title: term
      }
    })
  }
  if (filter.type !== undefined) {
    matches.push({
      term:{
        [filter.type]: {
          value: filter.val
        }
      }
    })
  }
  if (window.DSGlobal.property.lens !== undefined) {
    matches.push({
      terms: {
        conference_gig_ids: [
          window.DSGlobal.property.lens.id
        ]
      }
    })
  }
  return matches;
}

const searchQuery = (term, size = 25, from = 0, filter = {}) => {
  const parts = [
    {
      index: "bands_search_production",
      query: {
        _source: ['title', 'hometown', 'cloudinary_image', 'genre', 'genre_tag'],
        from,
        size,
        query: {
          bool: {
            must: bandsPropertyScoping(term, filter)
          }
        }
      }
    }
  ]
  const finalQuery = [];
  parts.forEach(part => (
    finalQuery.push(JSON.stringify({index: part.index}), JSON.stringify(part.query))
  ))
  const query = finalQuery.join("\n") + "\n";
  return query;
};

export default searchQuery;