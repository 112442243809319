import { all, fork } from 'redux-saga/effects';
import clipboardSagas from '../sagas';
import magicLinkSagas from '../../MagicLink/sagas';

export default function* () {
  yield all([
    fork(clipboardSagas),
    fork(magicLinkSagas),
  ]);
}
