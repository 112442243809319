import axios from 'axios';

class Service {
  constructor() {
    const service = axios.create({
      headers: { 'Content-Type': 'application/json', Accept: 'application/json' },
    });
    service.interceptors.response.use(this.handleSuccess, this.handleError);
    this.service = service;
  }

  handleSuccess = (response) => (
    response
  )
  
  get(path, callback, props) {
    return this.service.get(path).then(
      (response) => callback(response.status, response.data, props),
    ).catch((error) => callback(error.response.status, error.response.data));
  }

  patch(path, payload, callback) {
    return this.service.request({
      method: 'PATCH',
      url: path,
      responseType: 'json',
      data: payload,
    }).then((response) => callback(response.status, response.data));
  }

  put(path, payload, callback) {
    return this.service.request({
      method: 'PUT',
      url: path,
      responseType: 'json',
      data: payload,
    }).then((response) => callback(response.status, response.data)).catch((error) => callback(error.response.status, error.response.data));
  }

  post(path, payload, callback) {
    return this.service.request({
      method: 'POST',
      url: path,
      responseType: 'json',
      data: payload,
    }).then((response) => callback(response.status, response.data)).catch((error) => callback(error.response.status, error.response.data));
  }

  destroy(path, callback) {
    return this.service.request({
      method: 'DELETE',
      url: path,
      responseType: 'json',
    }).then((response) => callback(response.status, response.data));
  }
}

export default new Service();
