import React, { useState } from 'react';
import Modal from './Modal';

const MagicLink = ({ text }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <a
        role='button'
        href='magic_link'
        onClick={(e) => {
          e.preventDefault();
          setIsOpen(!isOpen);
        }}
      >
        {text}
      </a>
      <Modal
        isOpen={isOpen}
        toggleModal={() => setIsOpen(false)}
      />
    </>
  );
};

export default MagicLink;
