import React from 'react'

const Band = ({ item }) => (
  <React.Fragment>
    <div className="ds-search-result" data-ds-ga-label="label">
      <a href={`/artists/id/${item._id}`}>
        <span className="ds-result-title">
          {item._source.title}
          {item._source.hometown && <span> ({item._source.hometown})</span>}
        </span>
      </a>
    </div>
  </React.Fragment>
)

export default Band;
