import React, { useState } from 'react';
import ReactModal from 'react-modal';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { requestMagicLink } from './actions';

const Modal = ({ isOpen, toggleModal }) => {
  const { handleSubmit, register, formState: { errors }, watch } = useForm();
  const dispatch = useDispatch();
  const [requestError, setRequestError] = useState('');
  const [created, setCreated] = useState(false);
  const watchedEmail = watch('email');

  const onSubmit = data => (
    new Promise((resolve, reject) => (
      dispatch(requestMagicLink(data.email, resolve, reject))
    )).then(() => setCreated(true)).catch(d => setRequestError(d.response.data.error))
  );

  return (
    <ReactModal
      isOpen={isOpen}
      onAfterOpen={() => {
        document.body.style.overflowY = 'scroll';
      }}
      onAfterClose={() => {
        document.body.style.overflowY = 'unset';
        setCreated(false);
      }}
      style={{
        overlay: {
          zIndex: 1000,
          backgroundColor: '#282828',
        },
        content: {
          height: 'auto',
          textAlign: 'center',
          color: 'black',
        },
      }}
    >
      <div className="clipboard-bs clipboard-content">
        <button
          onClick={() => toggleModal()}
          aria-label="Dismiss alert"
          type="button"
          className="btn-x rounded-circle bg-light text-dark mt-2 fs-4"
          style={{ border: 'none', position: 'absolute', lineHeight: '26px', width: '28px', height: '28px', right: '8px', top: '0px' }}
        >
          &times;
        </button>
        {!created &&
          <form onSubmit={handleSubmit(onSubmit)}>
            <h5>Enter your email address and we will send you a link to login!</h5>
            <input
              type='email'
              name='email'
              style={{ width: '350px' }}
              placeholder='Enter email address...'
              {...register('email', {
                required: true,
              })}
            />
            {errors.email && <span style={{ color: '#dd2f25', display: 'block', margin: '4px 0px 4px 0px' }}>Invalid email address</span>}
            {requestError !== '' && <span style={{ color: '#dd2f25', display: 'block', margin: '4px 0px 4px 0px' }}>{requestError}</span>}
            {!errors.email && requestError === '' && <><br /><br /></>}
            <button disabled={!watchedEmail || watchedEmail === ''} type="submit" className="ds-btn ds-btn-medium" style={{ marginLeft: '5px' }}>
              Get Link
            </button>
          </form>
        }
        {created && <h3>Email sent!</h3>}
      </div>
    </ReactModal>
  );
};

export default Modal;
