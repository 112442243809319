import React from 'react';

const getName = (item) => (
  item._source.display_name !== null ? item._source.display_name : item._source.name
)

const User = ({ item }) => (
  <React.Fragment>
    <div className="ds-search-result" data-ds-ga-label="label">
      <a href={`/users/${item._id}`}>
        <span className="ds-result-title">{getName(item)}</span>
      </a>
    </div>
  </React.Fragment>
)

export default User;
