import React from 'react';

const Page = ({ item }) => (
  <React.Fragment>
    <div className="ds-search-result" data-ds-ga-label="label">
      <a href={`/p/${item._source.uri}`}>
        <span className="ds-result-title">{item._source.title}</span>
      </a>
    </div>
  </React.Fragment>
)

export default Page;
