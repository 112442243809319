import React from 'react';
import { Controller } from 'react-hook-form';
import classNames from 'classnames';
import InputMask from 'react-input-mask';
import { EMAIL_REGEX } from '../constants';

const Text = ({ control, index, trigger, name, required, isEmail, body, hint, isQuestion, id, mask, maskPlaceholder, type }) => (
  <>
    <Controller
      control={control}
      name={name}
      rules={{
        validate: (value) => {
          if (value === '' && required) {
            return 'Answer is required';
          }
          if (isEmail && !value.match(EMAIL_REGEX)) {
            return 'Email is invalid';
          }
        },
      }}
      render={(props) => (
        <>
          <label htmlFor="body" className="form-label p-0 m-0 mb-2 lh-1">{body}{required && <span style={{ display: 'inline' }} className="invalid-feedback"> *</span>}</label>
          <InputMask
            mask={mask}
            maskPlaceholder={maskPlaceholder}
            onChange={e => {
              props.field.onChange(e.target.value);
              trigger();
            }}
            value={props.field.value}
            className={classNames(
              'form-control mb-4 form-control-sm',
              props.fieldState.invalid && 'is-invalid'
            )}
          >
            <input
              type={type || 'text'}
              placeholder={hint}
            />
          </InputMask>
        </>
      )}
    />
    {isQuestion &&
      <Controller
        control={control}
        name={`answers[${index}].question_id`}
        defaultValue={id}
        render={(props) => (
          <>
            <input
              type='hidden'
              defaultValue={props.value}
            />
          </>
        )}
      />
    }
  </>
);

export default Text;
