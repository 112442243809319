
import React from 'react';
import ReactModal from 'react-modal';

const LegalModal = ({ legalModalOpen, loading, clipboard, setLegalModalOpen }) => (
  <ReactModal
    isOpen={legalModalOpen}
    onAfterOpen={() => {
      document.body.style.overflow = 'hidden';
    }}
    onAfterClose={() => {
      document.body.style.overflow = 'unset';
    }}
    style={{
      overlay: {
        zIndex: 1000,
        backgroundColor: 'rgb(0 0 0 / 85%)',
      },
    }}
  >
    {!loading &&
    <>
      <div className="clipboard-bs clipboard-content">
        <div dangerouslySetInnerHTML={{ __html: clipboard.setting.legal_copy }} />
        <button
          onClick={() => setLegalModalOpen(false)}
          aria-label="Dismiss alert"
          type="button"
          className="btn-x rounded-circle bg-light text-dark mt-2 fs-4"
          style={{ border: 'none', position: 'absolute', lineHeight: '26px', width: '28px', height: '28px', right: '8px', top: '0px' }}
        >
          &times;
        </button>
      </div>
      <div className="clipboard-bs clipboard-footer ">
        <div className="clipboard-disclaimer">
          <a role="button" className="float-right" onClick={() => setLegalModalOpen(false)}>Back</a>
        </div>
      </div>
    </>
    }
  </ReactModal>
);

export default LegalModal;
