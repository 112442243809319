
import { takeEvery, call } from 'redux-saga/effects';
import axios from 'axios';
import {
  REQUEST_MAGIC_LINK,
} from './constants';

export default function* watcherSaga() {
  yield takeEvery(REQUEST_MAGIC_LINK, requestMagicLink);
}

function* requestMagicLink({ email, resolve, reject }) {
  try {
    yield call(request, email);
    resolve();
  } catch (e) {
    reject(e);
  }
}

const request = (email) => {
  const getPartnerUrl = '/magic_links';
  const req = axios.post(getPartnerUrl, { email }).then(res => res);
  return req;
};
