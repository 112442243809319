import React from 'react'

const Event = ({ item }) => (
  <React.Fragment>
    <div className="ds-search-result" data-ds-ga-label="label">
      <a href={`/venues/id/${item._id}`}>
        <span className="ds-result-title">{item._source.title}</span>
        <span className="ds-result-metadata">
          {item._source.address}
          {item._source.neighborhood_title && <span> ({item._source.neighborhood_title})</span>}
        </span>
      </a>
    </div>
  </React.Fragment>
)

export default Event;
