import React, { useState } from 'react';
import Service from '../Utils/Service';
import searchQuery from '../Utils/searchQuery';

const HeaderSearch = () => {

  const [results, setResults] = useState({ events: [], users: [], bands: [], pages: [], venues: [] });
  const [term, setTerm] = useState('');
  const [suggestedVisible, setSuggestedVisible] = useState(true);

  const searchRequest = (term) => {
    const query = searchQuery(term);
    const request = Service.post(
      'https://search.dostff.co',
      query,
      (status, data) => {
        if (status === 200) {
          return data;
        }
      }
    );
    return request;
  }

  const getHighlightOrTitle = (item) => {
    if (item.highlight === undefined) {
      return item._source.title;
    } else {
      return item.highlight.title;
    }
  }

  const getHighlightOrName = (item) => {
    if (item.highlight === undefined) {
      if (item._source.display_name === null) {
        return item._source.name;
      } else {
        return item._source.display_name;
      }
    } else {
      return item.highlight.display_name;
    }
  }

  const queryEs = (_term) => {
    setTerm(_term);
    if (_term.length > 1) {
      const res = searchRequest(_term);
      setSuggestedVisible(false);
      res.then(data => {
        setResults({events: data.responses[0].hits.hits, users: data.responses[1].hits.hits, bands: data.responses[2].hits.hits, venues: data.responses[3].hits.hits, pages: data.responses[4].hits.hits });
      })
    } else if (_term.length === 1 || _term === "") {
      setSuggestedVisible(true);
      setTerm('');
      setResults({ events: [], users: [], bands: [], pages: [], venues: [] })
    }
  }

  return (
    <React.Fragment>
      <form className="ds-global-search-box" action="/search" method="get">
        <span className="ds-search-icon">
          <span className="ds-icon ds-icon-search2" />
        </span>
        <span className="ds-search-form">
          <input type='text' name="query" autoFocus autoComplete="off" onChange={(e) => queryEs(e.target.value) } />
        </span>
        <span className="ds-search-close">
          <a href="/close-search" onClick={(e) => e.preventDefault()}>
            <span className="ds-icon ds-icon-x" />
          </a>
        </span>
      </form>
      <ul className="ui-autocomplete ui-front ui-menu ui-widget ui-widget-content ui-corner-all" id="ui-id-1" style={(suggestedVisible ? {display: 'none'} : {})} tabIndex="0">
        {results.events.map(event => (
          <li key={`event-result-item-${event._id}`} className={`ds-search-result ${event._id} ds-ss-type-events ui-menu-item`} role="presentation">
            <a href={`/events/${event._id}`} className="ui-corner-all" tabIndex="-1">
              <span className="ds-icon ds-icon-cal" />
              <span className="ds-search-result-text">
                <span dangerouslySetInnerHTML={{ __html: getHighlightOrTitle(event) }} />
                <span className="ds-search-result-date"> &nbsp; {event._source.begin_date}</span>
              </span>
            </a>
          </li>
        ))}
        {results.users.map(user => (
          <li key={`user-result-item-${user._id}`} className={`ds-search-result ${user._id} ${user._source.user_type === 'promo' ? 'ds-ss-type-promo' : 'ds-ss-type-all_star'} ui-menu-item`} role="presentation">
            <a href={`/users/${user._id}`} className="ui-corner-all" tabIndex="-1">
              <span className={`ds-icon ${user._source.user_type === 'promo' ? 'ds-icon-list' : 'ds-icon-person'}`} />
              <span className="ds-search-result-text">
                <span dangerouslySetInnerHTML={{ __html: getHighlightOrName(user) }} />
              </span>
            </a>
          </li>
        ))}
        {results.bands.map(band => (
          <li key={`vands-result-item-${band._id}`} className={`ds-search-result ds-ss-type-bands ui-menu-item`} role="presentation">
            <a href={`/artists/id/${band._id}`} className="ui-corner-all" tabIndex="-1">
              <span className={`ds-icon ds-icon-speakers`} />
              <span className="ds-search-result-text">
                <span dangerouslySetInnerHTML={{ __html: getHighlightOrTitle(band) }} />
              </span>
            </a>
          </li>
        ))}
        {results.venues.map(venue => (
          <li key={`venues-result-item-${venue._id}`} className={`ds-search-result ds-ss-type-venues ui-menu-item`} role="presentation">
            <a href={`/venues/id/${venue._id}`} className="ui-corner-all" tabIndex="-1">
              <span className={`ds-icon ds-icon-marker`} />
              <span className="ds-search-result-text">
                <span dangerouslySetInnerHTML={{ __html: getHighlightOrTitle(venue) }} />
              </span>
            </a>
          </li>
        ))}
        {results.pages.map(page => (
          <li key={`pages-result-item-${page._id}`} className={`ds-search-result ds-ss-type-pages ui-menu-item`} role="presentation">
            <a href={`/p/${page._source.uri}`} className="ui-corner-all" tabIndex="-1">
              <span className={`ds-icon ds-icon-list`} />
              <span className="ds-search-result-text">
                <span dangerouslySetInnerHTML={{ __html: getHighlightOrTitle(page) }} />
              </span>
            </a>
          </li>
        ))}
        <li className="ds-global-search-all ds-search-result ui-menu-item" role="presentation" style={(suggestedVisible ? {display: 'none'} : {})}>
          <a href={`/search?query=${term}`} id="ui-id-4" className="ui-corner-all" tabIndex="-1">See All Results For {term}</a>
        </li>
      </ul>
      <div className="ds-default-suggest" style={(suggestedVisible ? {} : {display: 'none'})}>
        <div className="ds-suggest-tastemaker ds-suggestion">
          <p className="h4">
            <span className="ds-icon ds-icon-marker" />
            Recommendations by taste makers
          </p>
          <ol>
            {window.SEARCH.top_tastemakers.map(tastemaker => (
              <li key={`tastemaker-link-${tastemaker.table.id}`}>
                <a href={tastemaker.table.permalink} className="ds-ga-event" data-ds-ga-action="SEARCH" data-ds-ga-label="FEATURED_LIST_CLICK">{tastemaker.table.name}</a>
              </li>
            ))}
          </ol>
        </div>
        <div className="ds-suggest-search ds-suggestion">
          <p className="h4 ds-see-all">
            <span className="ds-icon ds-icon-list" />
            Featured Stuff
          </p>
          <ol>
            {window.SEARCH.featured_links.map(featured => (
              <li key={`featured-link-${featured.table.link_url}`}>
                <a href={featured.table.link_url} className="ds-ga-event" data-ds-ga-action="SEARCH" data-ds-ga-label="FEATURED_LIST_CLICK">{featured.table.link_title}</a>
              </li>
            ))}
          </ol>
        </div>
      </div>
    </React.Fragment>
  );
};

export default HeaderSearch;
