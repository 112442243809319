import React from 'react';
import { useSelector } from 'react-redux';
import { selectOfferText, selectTemplates } from './selectors';

const Header = ({ showOfferText }) => {
  const offerText = useSelector(selectOfferText);
  const template = useSelector(selectTemplates).filter(temp => temp.template_for.match(/top/));
  return (
    <>
      {showOfferText && 
        <h3 className="clipboard-offer-text">{offerText}</h3>
      }
      <div className="clipboard-top-text" dangerouslySetInnerHTML={{ __html: template[0].body }} />
    </>
  );
};

export default Header;
