import React from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { Controller } from 'react-hook-form';

const DatePicker = ({ control, index, trigger, name, required, body, isQuestion, id, minimumAge, errors }) => (
  <>
    <Controller
      control={control}
      name={name}
      rules={{
        validate: (value) => {
          if (value === '' && required) {
            return 'Answer is required';
          }
          if (minimumAge) {
            const age = moment().diff(value, 'years');
            if (age < minimumAge) {
              return `You must be at least ${minimumAge} years old`;
            }
          }
        },
      }}
      render={(props) => (
        <>
          <label htmlFor="body" className="form-label p-0 m-0 mb-2 lh-1">{body}{required && <span style={{ display: 'inline' }} className="invalid-feedback"> *</span>}</label>
          <span>{props.value}</span>
          <input
            type="date"
            className={classNames(
              'form-control form-control-sm',
              props.fieldState.invalid && 'is-invalid mb-0',
              !props.fieldState.invalid && 'mb-4',
            )}
            onChange={date => {
              props.field.onChange(date.target.value);
              trigger();
            }}
          />
        </>
      )}
    />
    {isQuestion &&
      <Controller
        control={control}
        name={`answers[${index}].question_id`}
        defaultValue={id}
        render={(props) => (
          <>
            <input
              type='hidden'
              defaultValue={props.field.value}
            />
          </>
        )}
      />
    }
  </>
);

export default DatePicker;
