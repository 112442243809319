import React from 'react';
import { useSelector } from 'react-redux';
import { selectTemplates, selectReferralCode } from './selectors';

const Entered = ({ url, showEntryConfirmation }) => {
  const templates = useSelector(selectTemplates);
  const referral_code = useSelector(selectReferralCode);
  const entryTemplate = templates.find(temp => temp.template_for.match(/^entry$/));

  return (
    <>
      {showEntryConfirmation && <p>Thanks for your entry!</p>}
      <div className="clipboard-entered" dangerouslySetInnerHTML={{ __html: entryTemplate.body }} />
      <p>
        You can send people this referral code: <a href={`${window.location.origin}${url}#ref-${referral_code}`}>{`${window.location.origin}/${url}#ref-${referral_code}`}</a>
      </p>
    </>
  );
};

export default Entered;
