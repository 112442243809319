import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ModalProvider } from 'react-modal-hook';
import { Provider } from 'react-redux';
import HeaderSearch from './HeaderSearch/HeaderSearch';
import FullSearch from './FullSearch/FullSearch';
import ConfSearch from './ConfSearch/ConfSearch';
import Event from './Event/Event';
import Clipboard from './Clipboard/Clipboard';
import MagicLink from './MagicLink/MagicLink';
import store from './Clipboard/store';

if (!(document.location.pathname.indexOf('/search') > -1)) {
  ReactDOM.render(<HeaderSearch />, document.getElementById('es-search'));
} else if ((document.location.pathname.indexOf('/search') > -1)) {
  ReactDOM.render(<FullSearch />, document.getElementById('es-fullsearch'));
  window.createSticky('.ds-listing-nav');
} 

if ((document.location.pathname.indexOf('/artists') > -1) && window.DSGlobal.property.lens !== undefined) {
  ReactDOM.render(<ConfSearch />, document.getElementById('es-conf-band-search'));
  window.createSticky('#sticky-search-bar');
}

const eventPage = document.getElementById('es-event-app');

if (eventPage !== null) {
  ReactDOM.render(<Event />, eventPage);
}

const clipboards = [];

const camelize = (str) => str.replace('-', ' ').replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => (index === 0 ? word.toLowerCase() : word.toUpperCase())).replace(/\s+/g, '');

const clipboardOptions = ['token', 'button-text', 'display', 'offer-text', 'url'];

if (clipboards.length > 0) {
  const options = {};
  Array.prototype.forEach.call(clipboards, (clipboard) => {
    Array.prototype.forEach.call(clipboardOptions, optionName => {
      const attr = clipboard.attributes[`data-clipboard-${optionName}`];
      if (attr) {
        if (attr.value === 'true') {
          options[camelize(optionName)] = true;
        } else if (attr.value === 'false') {
          options[camelize(optionName)] = false;
        } else {
          options[camelize(optionName)] = attr.value;
        }
      } else {
        options[camelize(optionName)] = true;
      }
    });
    ReactDOM.render(
      <Provider store={store}>
        <BrowserRouter>
          <ModalProvider>
            <Clipboard clipboardElement={clipboard} {...options} />
          </ModalProvider>
        </BrowserRouter>
      </Provider>,
      clipboard
    );
  });
}

const forgotPasswordElement = document.getElementById('forgot-password-link');

if (forgotPasswordElement) {
  const text = forgotPasswordElement.attributes['data-text'].value;
  const hasMagicLinks = forgotPasswordElement.attributes['data-magic-links'].value === 'true';
  if (hasMagicLinks) {
    ReactDOM.render(
      <Provider store={store}>
        <MagicLink text={text} /> 
      </Provider>,
      forgotPasswordElement
    );
  }
}
