import { takeEvery, call, put } from 'redux-saga/effects';
import axios from 'axios';
import {
  SET_CLIPBOARD,
  GET_CLIPBOARD,
  SUBMIT_ENTRY,
  ENTERED,
  TOGGLE_LOADING,
} from './constants';

export default function* watcherSaga() {
  yield takeEvery(GET_CLIPBOARD, getClipboard);
  yield takeEvery(SUBMIT_ENTRY, submitEntry);
}

function* getClipboard({ token, userId }) {
  try {
    const payload = yield call(retrieveClipboard, token, userId);
    yield put({ type: SET_CLIPBOARD, clipboard: payload.data.data });
    yield put({ type: TOGGLE_LOADING });
  } catch (e) {
    console.log('Oops. Something went wrong! Please contact support if this error persists.');
  }
}

function* submitEntry({ params }) {
  try {
    const payload = yield call(submitEntryRequest, params);
    yield put({ type: ENTERED, entry: payload.data.data, referral_code: payload.data.referral_url, existing_entry: payload.data.existing });
  } catch (e) {
    console.log('Oops. Something went wrong! Please contact support if this error persists.');
  }
}

const submitEntryRequest = (params) => {
  const url = 'https://clipboard.dostff.co/api/entries';
  const request = axios.post(url, { entry: params }).then(res => res);
  return request;
};

const retrieveClipboard = (token, userId) => {
  const getPartnerUrl = `https://clipboard.dostff.co/api/clipboard/${token}/${userId}`;
  const request = axios.get(getPartnerUrl).then(res => res);
  return request;
};
