import {
  REQUEST_MAGIC_LINK,
} from './constants';

export const requestMagicLink = (email, resolve, reject) => ({
  type: REQUEST_MAGIC_LINK,
  email,
  resolve,
  reject,
});
