import React from 'react';
import classNames from 'classnames';
import { Controller } from 'react-hook-form';

const Select = ({ control, index, trigger, name, required, body, isQuestion, id, options }) => (
  <>
    <Controller
      control={control}
      name={name}
      defaultValue="default"
      rules={{
        validate: (value) => {
          if (value === 'default' && required) {
            return `${body} is required`;
          }
        },
      }}
      render={(props) => (
        <>
          <label htmlFor="body" className="form-label p-0 m-0 mb-2 lh-1">{body}</label>
          <select
            onChange={e => {
              props.field.onChange(e.target.value);
              trigger();
            }}
            defaultValue={props.value}
            className={classNames(
              'form-select mb-4 form-select-sm',
              props.fieldState.invalid && 'is-invalid'
            )}
          >
            <option value="default">Pick one...</option>
            {options.map(option => <option key={`${body}-option-${option}`} value={option}>{option}</option>)}
          </select>
        </>
      )}
    />
    {isQuestion &&
      <Controller
        control={control}
        name={`answers[${index}].question_id`}
        defaultValue={id}
        render={(props) => (
          <>
            <input
              type='hidden'
              defaultValue={props.field.value}
            />
          </>
        )}
      />
    }
  </>
);

export default Select;
