import React, { useState, useEffect } from 'react';
import Service from '../Utils/Service';
import searchQuery from './searchQuery';
import Hit from './Hit';

const ConfSearch = () => {
  const [results, setResults] = useState({ bands: [] });
  const [counts, setCounts] = useState({ total: 0, bands: 0 });
  const [term, setTerm] = useState('');
  const [filter, setFilter] = useState({});
  const [size, setSize] = useState(50);
  const [from, setFrom] = useState(0);
  const [currentUser, setCurrentUser] = useState({logged_in: false});

  const searchRequest = (term, f) => {
    const query = searchQuery(term, size, from, f);
    const request = Service.post(
      '//search.dostff.co',
      query,
      (status, data) => {
        if (status === 200) {
          return data;
        }
      }
    );
    return request;
  }

  const fetchCurrentUser = () => {
    const request = Service.get(
      '/users/current.json',
      (status, data) => {
        if (status === 200) {
          return data;
        }
      }
    );
    return request;
  }

  const follow = (hit) => {
    const request = Service.post(
      `/artists/${hit._id}/follow.json`,
      '',
      (status, data) => {
        if (status === 200) {
          return data;
        }
      }
    );
    return request;
  }

  const queryEs = (_term, f = {}) => {
    setTerm(_term);
    let _filter = {}
    if (f ===  null) {
      _filter = {};
    } else if (f.val !== undefined) {
      _filter = f;
    } else if (filter.val !== '') {
      _filter = filter;
    }
    const res = searchRequest(_term, _filter);
    window.history.pushState(null, '', `/artists?query=${_term}`);
    res.then(data => {
      const total = data.responses.reduce((total, resultSet) => total + resultSet.hits.total.value, 0);
      setCounts({total, bands: data.responses[0].hits.hits.length});
      setResults({bands: data.responses[0].hits.hits});
    })
  }

  const filterQuery = (e, type, val) => {
    e.preventDefault();
    setFilter({type, val});
    queryEs('', {type, val})
  }

  const getUrlVars = () => {
    const vars = {};
    window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m,key,value) {
        vars[key] = value;
    });
    return vars;
  }

  const toggleFollow = (e, hit, currentUser) => {
    if (!currentUser.logged_in) {
      const path = `/users/sign_in?redirect=${encodeURIComponent(window.location.pathname)}&vote_type=artists&vote_id=${hit._id}`;
      window.location.href = path;
    }
    e.preventDefault();
    const tf = follow(hit);
    tf.then(data => {
      const fcu = fetchCurrentUser();
      fcu.then(data => {
        setCurrentUser(data);
      })
    })
  }

  const clearFilters = () => {
    setFilter({});
    queryEs('', null);
  }

  useEffect(() => {
    const ps = getUrlVars();
    const fcu = fetchCurrentUser();
    fcu.then(data => {
      setCurrentUser(data);
      if (ps.query !== undefined) {
        queryEs(decodeURI(ps.query));
      } else {
        queryEs('');
      }
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <div id="sticky-search-bar" className="ds-listing-nav section">
        <div className="ds-cover-image"></div>
        <div className="ds-container">
          <div className="ds-main">
            <div className="ds-results-groups-nav">
              <div className="conf-sort-toggle">
                <a href="/artists/alphabetically">View Artists A-Z &raquo;</a>
              </div>
              <div className="ds-field">
                <input type="text" id="conf-band-search-input" onChange={(e) => queryEs(e.target.value)} autoComplete='off' className="ds-stretch es-react-search" placeholder={`Search for bands playing ${window.SEARCH.lens_title}`} />
              </div>
              <div className="ds-conf-band-counts">{counts.bands} of {counts.total} Artists</div>
              <div className="ds-band-filters">
                {filter.val !== undefined && filter.type === 'genre_tag' && <React.Fragment><span onClick={() => clearFilters()} className="ds-icon ds-icon-x reset-filters"></span><span> GENRE: {filter.val}</span></React.Fragment>}
                {filter.val !== undefined && filter.type === 'hometown' && <React.Fragment><span onClick={() => clearFilters()} className="ds-icon ds-icon-x reset-filters"></span><span> HOMETOWN: {filter.val}</span></React.Fragment>}
              </div>
            </div>
          </div>
          <div className="ds-sidebar">
            
          </div>
        </div>
      </div>
      <div id="ds-paginated-list" className="section ds-listings-list ds-search-listings">
        <div className="ds-listings-main section">
          <div className="ds-container">
            <div className="ds-main">
              <div className="results" style={{minHeight: "600px"}}>
                {results.bands.map(hit => (
                  <Hit key={`conf-hit-${hit._id}`} hit={hit} filterQuery={filterQuery} currentUser={currentUser} toggleFollow={toggleFollow} />
                ))}
              </div>
            </div>
            <div className="ds-sidebar  ds-listing-sidebar">
              {window.SEARCH.sidebar_top !== undefined && <span dangerouslySetInnerHTML={{__html: window.SEARCH.sidebar_top}} />}
              <div className="ds-ad"></div>
              <div className="ds-display-ad ds-module" id="ds-sidebar-ad"></div>
              <div className="ds-featured-events" style={{display: "none"}}>
                <h3>Featured Events</h3>
                <div className="ds-module-featured-events"></div>
              </div>
              {window.SEARCH.sidebar_bottom !== undefined && <span dangerouslySetInnerHTML={{__html: window.SEARCH.sidebar_bottom}} />}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
};

export default ConfSearch;
