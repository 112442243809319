import {
  GET_CLIPBOARD,
  SUBMIT_ENTRY,
  TOGGLE_PARTNER_OPTIN,
  CLEAR_CLIPBOARD,
} from './constants';

export const getClipboard = (token, userId) => ({
  type: GET_CLIPBOARD,
  token,
  userId,
});

export const submitEntry = (params) => ({
  type: SUBMIT_ENTRY,
  params,
});

export const togglePartnerOptin = () => ({
  type: TOGGLE_PARTNER_OPTIN,
});

export const clearClipboard = () => ({
  type: CLEAR_CLIPBOARD,
});
