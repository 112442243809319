import React, { useState } from 'react';
import PulseLoader from 'react-spinners/PulseLoader';
import Questions from './Questions';
import Header from './Header';
import Bottom from './Bottom';
import ExistingEntry from './ExistingEntry';
import Entered from './Entered';
import LegalModal from './LegalModal';

const Inline = ({
  loading,
  clipboard,
  formProps,
  token,
  entry,
  handleSubmit,
  offerText,
  buttonText,
  url,
}) => {
  const [legalModalOpen, setLegalModalOpen] = useState(false);

  const toggleShowLegal = () => {
    if (clipboard.setting.legal_url !== '' && clipboard.setting.legal_url !== null) {
      window.open(clipboard.setting.legal_url, '_blank');
    } else {
      setLegalModalOpen(!legalModalOpen);
    }
  };

  return (
    <div className="clipboard-bs clipboard-content">
      {loading && <div style={{ textAlign: 'center' }} className="mt-5"><PulseLoader size={10} /></div>}
      {!loading &&
        <>
          <>
            <Header showOfferText={offerText} />
            {clipboard && clipboard.existing_entry && <ExistingEntry url={url} existingEntry={clipboard.existing_entry} />}
            {clipboard && !clipboard.existing_entry && !entry && <Questions {...formProps} token={token} />}
            {clipboard && entry && <Entered url={url} />}
            <Bottom />
          </>
          {!loading &&
            <div className="clipboard-bs clipboard-footer-inline">
              <div className="clipboard-disclaimer-inline">
                {clipboard.setting.auto_optin &&
                  <span>Your entry will also register you for Do312, where you'll get the best of what to do in Chicago every week. </span>
                }
                <a role="button" className="float-right" onClick={() => toggleShowLegal()}>Terms & Conditions</a>
                <LegalModal
                  legalModalOpen={legalModalOpen}
                  setLegalModalOpen={setLegalModalOpen}
                  clipboard={clipboard}
                />
              </div>
              <button 
                type="submit" 
                className="btn btn-secondary btn-lg" 
                onClick={() => handleSubmit()}
                disabled={!formProps.formState.isValid}
              >
                {buttonText}
              </button>
            </div>
          }
        </>
      }
    </div>
  );
};

export default Inline;
