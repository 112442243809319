import React from 'react';
import ReactModal from 'react-modal';
import PacmanLoader from 'react-spinners/PacmanLoader';
import ReCAPTCHA from 'react-google-recaptcha';
import Questions from './Questions';
import Header from './Header';
import Bottom from './Bottom';
import ExistingEntry from './ExistingEntry';
import Entered from './Entered';

const Modal = ({
  modalOpen,
  loading,
  clipboard,
  formProps,
  token,
  entry,
  toggleModal,
  showLegal,
  toggleShowLegal,
  handleSubmit,
  buttonText,
  offerText,
  url,
  onRecaptchChange,
  onRecaptchError,
  recaptchaVerified,
  showEntryConfirmation,
}) => (
  <>
    <ReactModal
      isOpen={modalOpen}
      onAfterOpen={() => {
        document.body.style.overflowY = 'scroll';
      }}
      onAfterClose={() => {
        document.body.style.overflowY = 'unset';
      }}
      style={{
        overlay: {
          zIndex: 1000,
          backgroundColor: 'rgb(0 0 0 / 85%)',
        },
      }}
    >
      <div className="clipboard-bs clipboard-content">
        {loading && <div style={{ textAlign: 'center' }} className="mt-5"><PacmanLoader size={50} /></div>}
        {!loading &&
          <>
            {!showLegal &&
              <>
                <Header showOfferText={offerText} />
                {clipboard && clipboard.existing_entry && <ExistingEntry showEntryConfirmation={showEntryConfirmation} url={url} existingEntry={clipboard.existing_entry} />}
                {clipboard && !clipboard.existing_entry && !entry && <Questions {...formProps} token={token} />}
                {clipboard && entry && !clipboard.existing_entry && <Entered url={url} />}
                <Bottom />
              </>
            }
            {showLegal && <div dangerouslySetInnerHTML={{ __html: clipboard.setting.legal_copy }} /> }
            <button
              onClick={() => toggleModal()}
              aria-label="Dismiss alert"
              type="button"
              className="btn-x rounded-circle bg-light text-dark mt-2 fs-4"
              style={{ border: 'none', position: 'absolute', lineHeight: '26px', width: '28px', height: '28px', right: '8px', top: '0px' }}
            >
              &times;
            </button>
          </>
        }
      </div>
      {!loading && !clipboard.existing_entry && !entry &&
        <div className="clipboard-bs clipboard-footer ">
          <div className="clipboard-disclaimer">
            {!showLegal && clipboard.setting.auto_optin &&
              <span>Your entry will also register you for Do312, where you'll get the best of what to do in Chicago every week. </span>
            }
            <a role="button" className="float-right" onClick={() => toggleShowLegal()}>{showLegal ? 'Back' : 'Terms & Conditions'}</a>
          </div>
          {!showLegal &&
            <>
              <ReCAPTCHA
                sitekey="6Lc_vsoaAAAAAOGknHPTQHfMJF4gk9cdUbL_AGSm"
                onChange={onRecaptchChange}
                onErrored={onRecaptchError}
              />
              <button 
                type="submit" 
                className="btn btn-secondary btn-lg" 
                onClick={() => handleSubmit()}
                disabled={!formProps.formState.isValid || !recaptchaVerified}
              >
                Submit
              </button>
            </>
          }          
        </div>
      }
    </ReactModal>

    <div className="ds-btn-container" onClick={() => toggleModal()} role='button'>
      <a className="ds-btn stretch ds-btn-large ds-btn-win ds-actionable">
        <span
          className="ds-default"
        >
          <span className="ds-icon ds-icon-ticket ds-icon-bg" />
          <span className="ds-icon-text">
            {buttonText}
          </span>
        </span>
      </a>
    </div>
  </>
);

export default Modal;
