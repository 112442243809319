import { createSelector } from 'reselect';

const clipboardSelector = state => state.clipboard.clipboard;

export const selectClipboard = createSelector(
  state => state.clipboard,
  clipboard => clipboard.clipboard
);

export const selectOfferText = createSelector(
  state => state.clipboard,
  clipboard => clipboard.clipboard.setting.offer
);

export const selectMinimumAge = createSelector(
  state => state.clipboard,
  clipboard => clipboard.clipboard.setting.minimum_age
);

export const selectQuestions = createSelector(
  state => state.clipboard,
  clipboard => clipboard.clipboard.questions.sort((a, b) => a.ordering - b.ordering)
);

export const selectLoggedIn = createSelector(
  state => state.clipboard,
  clipboard => clipboard.currentUser.logged_in
);

export const selectCurrentUser = createSelector(
  state => state.clipboard,
  clipboard => clipboard.currentUser
);

export const selectExistingEntry = createSelector(
  [clipboardSelector],
  clipboard => clipboard.existing_entry
);

export const selectEntry = createSelector(
  state => state.clipboard,
  clipboard => clipboard.entry
);

export const selectReferralCode = createSelector(
  state => state.clipboard,
  clipboard => clipboard.referral_code
);

export const selectPartner = createSelector(
  [clipboardSelector],
  clipboard => clipboard.giveaway_partners[0]
);

export const selectPartnerOptin = createSelector(
  state => state.clipboard,
  clipboard => clipboard.partnerOptin
);

export const selectLoading = createSelector(
  state => state.clipboard,
  clipboard => clipboard.loading
);

export const selectTemplates = createSelector(
  [clipboardSelector, selectLoggedIn],
  (clipboard, loggedIn) => {
    const templates = [];
    templates.push(clipboard.giveaway_templates.filter(temp => temp.template_for.match(/entry/)));
    if (loggedIn) {
      templates.push(clipboard.giveaway_templates.filter(temp => temp.template_for.match(/logged_in/)));
    } else {
      templates.push(clipboard.giveaway_templates.filter(temp => temp.template_for.match(/logged_out/)));
    }
    return templates.flat();
  }
);
